body {
  color: #000;
  background-color: #fff;
}

/* html {
  min-height: 100%;
} */

.navb {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
}

/* .logo {
  height: 250px;
} */

.mintcon {
  margin-top: 10rem;
  margin-bottom: 2rem;
}

.cusbtn {
  border: 1px solid #000;
  border-radius: 10px;
  /* padding: 20px 35px; */
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
  font-size: 24px;
  max-width: 360px;
  line-height: 1.3em;
  /* font-weight: 600; */
  letter-spacing: 1px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.cusbtn:hover {
  color: #000;
}

.mintbutton {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  letter-spacing: 3px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: 6px solid #000;
}

.mint {
  margin-top: 6rem;
  background-color: #161f238c;
  padding: 3rem;
  border-radius: 1.5rem;
}
.mih1 {
  font-weight: bold;
  font-size: 2.7rem;
  letter-spacing: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.subtitle {
  font-size: 1.3rem;
  letter-spacing: 3px;
}

.plus-img {
  height: 60px;
  cursor: pointer;
  border-radius: 1rem;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
  font-size: 30px;
}

.display-number {
  color: #000;
  background-color: #fff;
  height: 70px;
  width: 70px;
  border-radius: 4px;
}
.number {
  font-weight: bold;
  font-size: 3.3rem;
}

.si {
  height: 50px;
  margin: 0 10px;
}

.imgbg {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%), 4px 6px 10px rgb(0 0 0 / 10%),
    4px 7px 10px rgb(0 0 0 / 40%);
  padding: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .navb {
    flex-direction: column;
    justify-content: center;
  }
  .rmt {
    margin-top: 1rem;
  }
  .mintcon {
    margin-top: 5rem !important;
  }
}
